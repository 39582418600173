.header {
  padding: 30px;
  display: flex;
  height: 70px;
  z-index: 5;
  font-family: "Morganite-Light"; }
  .header .logo {
    color: #fff;
    width: 155px;
    padding: 2px;
    position: absolute; }
    .header .logo h1 {
      font-family: "Morganite-Light";
      font-size: 38px;
      letter-spacing: 3px;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      line-height: 35px;
      text-shadow: #fff 0px -2px -2px; }
    .header .logo span.subtitle {
      font-size: 28px;
      font-family: "Morganite-Thin";
      text-transform: uppercase;
      letter-spacing: 3px;
      text-shadow: #fff 0px 0px 1px;
      color: #fff;
      font-weight: 700;
      padding-left: 9px; }
  .header .nav {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
  .header ul {
    display: flex;
    text-decoration: none;
    list-style: none;
    font-size: 60px;
    margin: 0px;
    font-family: "Morganite-ExtraLightItalic"; }
    .header ul li {
      position: relative; }
      .header ul li .line {
        height: 2px;
        background: #FFF;
        transition: 0.5s;
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0%;
        pointer-events: none;
        transform: translate(-50%, -50%); }
      .header ul li.active .line, .header ul li:hover .line {
        width: 100%; }
    .header ul li.active a {
      background: -webkit-linear-gradient(#fc009d, #2641fe);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600; }
    .header ul a {
      display: block;
      text-decoration: none;
      color: #fff;
      padding: 0 10px;
      letter-spacing: 4px; }
      .header ul a:hover {
        background: -webkit-linear-gradient(#fc009d, #2641fe);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600; }

.header.blackColor {
  transition: 1s; }
  .header.blackColor .logo,
  .header.blackColor span.subtitle,
  .header.blackColor ul a {
    color: #000; }
  .header.blackColor ul a:hover {
    color: #f00; }
  .header.blackColor span.subtitle {
    text-shadow: #000 0px 0px 1px; }

.hamburger {
  font: inherit;
  display: none;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  z-index: 3;
  position: relative; }

.hamburger.is-active:hover,
.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
  background-color: #fff; }

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px; }

.hamburger-inner {
  top: 50%;
  display: block;
  /* margin-top:-2px; */ }

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #fff; }

.hamburger-inner:after,
.hamburger-inner:before {
  display: block;
  content: ""; }

.hamburger-inner:before {
  top: -10px; }

.hamburger-inner:after {
  bottom: -10px; }

.hamburger--squeeze .hamburger-inner:before {
  transition: top 75ms ease 0.12s, opacity 75ms ease; }

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg); }

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
  opacity: 0; }

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  transform: rotate(-90deg); }

.blackColor .hamburger-inner, .blackColor .hamburger-inner:after, .blackColor .hamburger-inner:before {
  background-color: black; }

.blackColor .nav.is-active ul a {
  color: white; }

.blackColor .nav.is-active ul a.active {
  color: red; }

@media (max-width: 801px) {
  .header {
    padding: 30px 15px; }
    .header .logo {
      position: relative; }
  .hamburger {
    display: inline-block; }
  .header .nav {
    justify-content: flex-end; }
    .header .nav .nav-container {
      display: none; }
  .header .nav.is-active .nav-container {
    display: block;
    position: fixed;
    width: 300px;
    padding: 50px;
    padding-top: 125px;
    background: #000;
    top: 0px;
    right: 0px;
    height: 100%; }
    .header .nav.is-active .nav-container ul {
      display: block; } }

@media (max-width: 480px) {
  .header .nav.is-active .nav-container {
    width: calc(100% - 75px); } }
