.section-about {
  position: fixed;
  width: 100%;
  height: 100%; }
  .section-about .about-left {
    position: fixed;
    background-color: #000;
    top: 0;
    left: 0;
    width: 65%;
    height: 100%;
    z-index: 2; }
    .section-about .about-left .about-container {
      width: 750px;
      right: -124px;
      height: 500px;
      position: absolute;
      top: 25%;
      display: flex;
      justify-content: space-between; }
      .section-about .about-left .about-container .description-container {
        width: 420px;
        padding-right: 25px;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        position: relative; }
        .section-about .about-left .about-container .description-container .since-text {
          transform: rotate(-90deg);
          height: 50px;
          font-family: "Morganite-ExtraLight";
          font-size: 60px;
          letter-spacing: 17px;
          color: #fff;
          top: 139px;
          left: -183px;
          position: absolute; }
        .section-about .about-left .about-container .description-container h2 {
          font-size: 22px;
          margin-top: 0px; }
        .section-about .about-left .about-container .description-container p {
          font-size: 14px; }
      .section-about .about-left .about-container .timeline {
        width: 250px; }
        .section-about .about-left .about-container .timeline h2 {
          color: #fff;
          font-size: 24px; }
        .section-about .about-left .about-container .timeline ul {
          padding: 0;
          margin: 0;
          list-style: none;
          color: #fff; }
          .section-about .about-left .about-container .timeline ul li {
            width: 102px;
            position: relative;
            padding-right: 24px;
            margin-bottom: 25px; }
          .section-about .about-left .about-container .timeline ul .job:before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border: 2px solid #fff;
            background-color: #fff;
            border-radius: 100%;
            top: 40%;
            right: -5px;
            z-index: 99; }
          .section-about .about-left .about-container .timeline ul .job span {
            display: block;
            font-size: 12px;
            padding: 2px 0px;
            font-weight: bold; }
          .section-about .about-left .about-container .timeline ul .job .company {
            letter-spacing: 3px; }
          .section-about .about-left .about-container .timeline ul .job .office {
            background: #fff;
            color: #000;
            letter-spacing: 2.7px;
            text-transform: uppercase; }
          .section-about .about-left .about-container .timeline ul .job .years {
            background: #000;
            position: absolute;
            top: 16%;
            right: -95%;
            padding: 2px 5px;
            font-size: 30px;
            letter-spacing: 2.5px;
            font-family: "Morganite-ExtraLight";
            font-weight: 400; }
  .section-about .about-right {
    position: fixed;
    top: 0;
    right: 0;
    width: 35%;
    height: 100%;
    z-index: 1; }
    .section-about .about-right .bg-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(src/assets/image/about/img-about-268c8965.jpg);
      background-size: cover; }
  .section-about .next {
    width: 80% !important;
    left: 10%;
    text-align: center;
    margin-top: 40px !important;
    padding: 0px 0px !important;
    background-color: 0; }
    .section-about .next .job:before {
      display: none; }
    .section-about .next .job .icon-message {
      font-size: 26px;
      background: #000;
      border-radius: 30px;
      padding: 10px; }
    .section-about .next .job a {
      font-size: 12px !important;
      display: block;
      color: #fff !important;
      background: #000 !important;
      text-decoration: none;
      padding: 10px;
      border-radius: 30px; }
      .section-about .next .job a:hover {
        color: #000 !important;
        background: #fff !important;
        font-weight: bold; }

@media (max-width: 1120px) {
  .description-container .since-text {
    display: none; } }

@media (max-width: 1025px) {
  .section-about .about-left .about-container {
    width: 660px; }
    .section-about .about-left .about-container .description-container {
      width: 360px; } }

@media (max-width: 880px) {
  .section-about .about-left {
    width: 100%; }
    .section-about .about-left .about-container {
      right: calc(50% - 330px); }
  .section-about .about-right {
    display: none; } }

@media (max-width: 731px) {
  .section-about {
    position: relative; }
    .section-about .about-left {
      position: relative;
      background: none; }
      .section-about .about-left .about-container {
        width: auto;
        display: block;
        padding: 30px 15px;
        right: auto;
        height: auto;
        position: relative;
        top: 0; }
        .section-about .about-left .about-container .description-container {
          width: auto; }
        .section-about .about-left .about-container .timeline {
          padding: 50px 0 20px 0;
          width: auto;
          background: #000; }
          .section-about .about-left .about-container .timeline ul {
            width: 250px;
            margin: 0 auto; }
        .section-about .about-left .about-container .next .job a {
          background: #fff !important;
          color: black !important;
          font-weight: 700; } }
