.footer {
  padding: 20px 30px;
  bottom: 0px;
  z-index: 5;
  color: #fff;
  display: flex;
  justify-content: space-between; }
  .footer .footer-links {
    font-size: 20px;
    list-style: none;
    padding: 0;
    margin: 0px;
    display: flex; }
    .footer .footer-links li {
      align-self: center; }
      .footer .footer-links li a {
        color: #fff;
        display: block;
        padding: 0 5px; }
  .footer .powredby {
    display: flex; }
    .footer .powredby .text {
      text-align: right;
      font-size: 11px;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 14px;
      padding: 6px 10px; }
    .footer .powredby .year {
      font-size: 36px;
      font-family: "Morganite-Light"; }

.footer.blackColor {
  color: #000; }
  .footer.blackColor .footer-links a {
    color: #000; }

@media (max-width: 801px) {
  .footer {
    padding: 20px 15px; } }
