@font-face {
  font-family: 'Morganite-SemiBoldItalic';
  src: url("/src/assets/fonts/morganite/Morganite-SemiBoldItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-ThinItalic';
  src: url("/src/assets/fonts/morganite/Morganite-ThinItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Bold';
  src: url("/src/assets/fonts/morganite/Morganite-Bold.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Bold.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Thin';
  src: url("/src/assets/fonts/morganite/Morganite-Thin.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Thin.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-ExtraBold';
  src: url("/src/assets/fonts/morganite/Morganite-ExtraBold.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-MediumItalic';
  src: url("/src/assets/fonts/morganite/Morganite-MediumItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-ExtraLightItalic';
  src: url("/src/assets/fonts/morganite/Morganite-ExtraLightItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-BoldItalic';
  src: url("/src/assets/fonts/morganite/Morganite-BoldItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Medium';
  src: url("/src/assets/fonts/morganite/Morganite-Medium.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Medium.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Black';
  src: url("/src/assets/fonts/morganite/Morganite-Black.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Black.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-BookItalic';
  src: url("/src/assets/fonts/morganite/Morganite-BookItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-ExtraLight';
  src: url("/src/assets/fonts/morganite/Morganite-ExtraLight.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-SemiBold';
  src: url("/src/assets/fonts/morganite/Morganite-SemiBold.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-BlackItalic';
  src: url("/src/assets/fonts/morganite/Morganite-BlackItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-LightItalic';
  src: url("/src/assets/fonts/morganite/Morganite-LightItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Book';
  src: url("/src/assets/fonts/morganite/Morganite-Book.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Book.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-ExtraBoldItali';
  src: url("/src/assets/fonts/morganite/Morganite-ExtraBoldItalic.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Morganite-Light';
  src: url("/src/assets/fonts/morganite/Morganite-Light.woff2") format("woff2"), url("/src/assets/fonts/morganite/Morganite-Light.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

html {
  width: 100%;
  height: 100%;
  background-color: black; }

body {
  margin: 0px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%; }

.loading {
  z-index: 99999;
  width: 100%;
  height: 100%;
  position: fixed;
  background: black;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center; }
  .loading svg {
    align-self: center; }

.full-screen {
  height: 100%; }

.appflex {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .appflex .wrapper {
    flex-grow: 1;
    flex-basis: auto; }
    .appflex .wrapper .container {
      height: 100%; }

.container {
  margin: 0 auto;
  max-width: 1200px; }

.layer-particle {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  position: fixed; }

#tsparticles {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
