.section-skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }

.section-skills .container-section {
  display: flex;
  align-self: center;
  width: 100%; }
  .section-skills .container-section .motion-title {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute; }
  .section-skills .container-section .back-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    position: relative; }
    .section-skills .container-section .back-container h2 {
      transform: rotate(-90deg);
      position: absolute;
      top: 147px;
      right: -36px;
      letter-spacing: 14px;
      width: 190px; }
    .section-skills .container-section .back-container li {
      width: 280px;
      margin-bottom: 25px;
      margin-right: 0px; }
      .section-skills .container-section .back-container li h4 {
        text-align: right;
        color: #fff;
        font-family: "Morganite-Light";
        font-size: 32px;
        margin: 0;
        padding: 10px 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold; }
    .section-skills .container-section .back-container ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      /* justify-content: flex-end; */
      align-items: flex-end; }
      .section-skills .container-section .back-container ul li:nth-child(1) {
        margin-right: 60px !important; }
        .section-skills .container-section .back-container ul li:nth-child(1) .parcent-count {
          width: 60%; }
      .section-skills .container-section .back-container ul li:nth-child(2) {
        margin-right: 172px; }
        .section-skills .container-section .back-container ul li:nth-child(2) .parcent-count {
          width: 45%; }
      .section-skills .container-section .back-container ul li:nth-child(3) {
        margin-right: 162px; }
        .section-skills .container-section .back-container ul li:nth-child(3) .parcent-count {
          width: 70%; }
      .section-skills .container-section .back-container ul li:nth-child(4) {
        margin-right: 140px; }
        .section-skills .container-section .back-container ul li:nth-child(4) .parcent-count {
          width: 70%; }
      .section-skills .container-section .back-container ul li:nth-child(5) {
        margin-right: 80px; }
        .section-skills .container-section .back-container ul li:nth-child(5) .parcent-count {
          width: 40%; }
  .section-skills .container-section .front-container {
    flex-grow: 1;
    width: 50%;
    position: relative; }
    .section-skills .container-section .front-container h2 {
      position: absolute;
      left: -35px;
      transform: rotate(90deg);
      top: 147px;
      letter-spacing: 3px; }
    .section-skills .container-section .front-container ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      /* justify-content: flex-end; */
      align-items: flex-start; }
      .section-skills .container-section .front-container ul li:nth-child(1) {
        margin-left: 60px !important; }
        .section-skills .container-section .front-container ul li:nth-child(1) .parcent-count {
          width: 90%; }
      .section-skills .container-section .front-container ul li:nth-child(2) {
        margin-left: 172px; }
      .section-skills .container-section .front-container ul li:nth-child(3) {
        margin-left: 162px; }
        .section-skills .container-section .front-container ul li:nth-child(3) .parcent-count {
          width: 70%; }
      .section-skills .container-section .front-container ul li:nth-child(4) {
        margin-left: 140px; }
        .section-skills .container-section .front-container ul li:nth-child(4) .parcent-count {
          width: 70%; }
      .section-skills .container-section .front-container ul li:nth-child(5) {
        margin-left: 80px; }
        .section-skills .container-section .front-container ul li:nth-child(5) .parcent-count {
          width: 70%; }
    .section-skills .container-section .front-container li {
      width: 280px;
      margin-bottom: 25px;
      margin-right: 0px; }
      .section-skills .container-section .front-container li h4 {
        text-align: left;
        color: #fff;
        font-family: "Morganite-Light";
        font-size: 32px;
        margin: 0;
        padding: 10px 0;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold; }
      .section-skills .container-section .front-container li .parcent-count {
        left: 0;
        background-image: linear-gradient(to right, #2641fe, #fc009d); }
  .section-skills .container-section h2 {
    font-family: "Morganite-Black";
    font-size: 140px;
    margin: 0;
    padding: 0;
    color: white;
    line-height: 100px; }
  .section-skills .container-section .percent {
    height: 7px;
    overflow: hidden;
    position: relative;
    border-radius: 15px; }
    .section-skills .container-section .percent .parcent-count {
      position: absolute;
      right: 0;
      border-radius: 15px;
      width: 90%;
      height: 100%;
      background-image: linear-gradient(to right, #fc009d, #2641fe);
      z-index: 2; }
    .section-skills .container-section .percent .parcent-background {
      background-color: #b7b7b7;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.3; }

@media (max-width: 940px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .section-skills .container-section .back-container li {
    width: 230px; }
  .section-skills .container-section .front-container li {
    width: 230px; } }

@media (max-width: 801px) {
  .section-skills .container-section {
    width: auto;
    padding: 30px 15px;
    display: block;
    align-self: auto; }
    .section-skills .container-section .back-container, .section-skills .container-section .front-container {
      display: block;
      width: 100%; }
      .section-skills .container-section .back-container h2, .section-skills .container-section .front-container h2 {
        transform: rotate(0deg);
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        font-size: 70px;
        letter-spacing: normal;
        line-height: normal; }
      .section-skills .container-section .back-container ul, .section-skills .container-section .front-container ul {
        align-items: flex-start;
        width: 95%;
        margin: 0 auto;
        padding: 20px 0; }
        .section-skills .container-section .back-container ul li:nth-child(1), .section-skills .container-section .front-container ul li:nth-child(1) {
          margin-left: 0px !important; }
        .section-skills .container-section .back-container ul li:nth-child(2), .section-skills .container-section .front-container ul li:nth-child(2) {
          margin-left: 0px; }
        .section-skills .container-section .back-container ul li:nth-child(3), .section-skills .container-section .front-container ul li:nth-child(3) {
          margin-left: 0px; }
        .section-skills .container-section .back-container ul li:nth-child(4), .section-skills .container-section .front-container ul li:nth-child(4) {
          margin-left: 0px; }
        .section-skills .container-section .back-container ul li:nth-child(5), .section-skills .container-section .front-container ul li:nth-child(5) {
          margin-left: 0px; }
        .section-skills .container-section .back-container ul li, .section-skills .container-section .front-container ul li {
          margin-left: 0px !important;
          width: 100%; }
          .section-skills .container-section .back-container ul li h4, .section-skills .container-section .front-container ul li h4 {
            text-align: left; }
    .section-skills .container-section .percent .parcent-count {
      left: 0;
      background-image: linear-gradient(to left, #fc009d, #2641fe); } }

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */ }

@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }

@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */ }

@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */ }

@media (min-width: 1281px) {
  /* hi-res laptops and desktops */ }
