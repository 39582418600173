.section-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .section-home .home-container {
    align-self: center;
    position: relative;
    text-align: center;
    color: white; }
    .section-home .home-container h2 {
      font-family: 'Morganite-ExtraBold';
      font-size: 197px;
      margin: 0;
      padding: 0;
      letter-spacing: 15px; }
    .section-home .home-container h3 {
      font-family: 'Morganite-ExtraLight';
      margin: 0;
      padding: 0;
      font-size: 48px;
      letter-spacing: 20px; }
  .section-home .imagebox {
    display: block;
    float: left;
    height: 100%; }
  .section-home .img-0 {
    width: 200px;
    margin: 0 30px 0 0; }
    @media (max-width: 1025px) {
      .section-home .img-0 {
        display: none; } }
  .section-home .img-1 {
    width: 225px;
    margin: 0 30px 0 0; }
    @media (max-width: 801px) {
      .section-home .img-1 {
        display: none; } }
  .section-home .img-2 {
    width: 330px;
    margin: 0 30px 0 0; }
    @media (max-width: 801px) {
      .section-home .img-2 {
        width: 480px;
        margin: 0; } }
  .section-home .intro-text {
    position: absolute;
    top: 30px;
    right: -70px;
    color: #fff;
    font-family: "Morganite-Light";
    font-size: 100px;
    line-height: 84px; }
    .section-home .intro-text .text-1 {
      font-family: "Morganite-Medium"; }
    @media (max-width: 801px) {
      .section-home .intro-text {
        right: 0;
        left: 15px; } }
    @media (max-width: 600px) {
      .section-home .intro-text {
        font-size: 15vw;
        line-height: 13vw;
        font-smooth: always; } }

@media (max-width: 600px) {
  .section-home {
    padding: 0 15px; }
    .section-home .home-container {
      align-self: auto; }
      .section-home .home-container .img-2 {
        width: 100%; } }

.img-0.slide-in-elliptic-left-fwd {
  -webkit-animation: slide-in-elliptic-left-fwd 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-left-fwd 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.img-1.slide-in-elliptic-left-fwd {
  -webkit-animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.img-2.slide-in-elliptic-left-fwd {
  -webkit-animation: slide-in-elliptic-left-fwd 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-elliptic-left-fwd 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

/* ----------------------------------------------
 * Generated by Animista on 2020-3-24 19:29:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1; } }

@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
    transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
    transform-origin: 50% 1400px;
    opacity: 1; } }

/* ----------------------------------------------
 * Generated by Animista on 2020-3-24 19:48:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd 
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1; } }

@keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
    transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
    transform-origin: -100% 50%;
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
    transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
    transform-origin: 1800px 50%;
    opacity: 1; } }

@media (max-width: 600px) {
  .section-home .home-container h2 {
    font-size: 25vw; }
  .section-home .home-container h3 {
    font-size: 7vw; } }
