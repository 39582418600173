.section-contact {
  display: flex;
  width: 100%;
  align-self: center;
  height: inherit; }
  .section-contact .layer-transparent {
    background-size: cover;
    position: fixed;
    width: 65%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: white; }
  .section-contact .container-section {
    position: relative;
    color: #fff;
    z-index: 2;
    width: 100%;
    align-self: center;
    height: 380px; }
    .section-contact .container-section .right-container {
      width: 65%;
      height: 100%;
      position: fixed;
      top: 0;
      right: 0; }
      .section-contact .container-section .right-container .contact-container {
        left: 80px;
        height: 500px;
        position: absolute;
        top: 25%;
        justify-content: space-between; }
    .section-contact .container-section .left-container {
      position: fixed;
      width: 35%;
      height: 100%;
      left: 0;
      top: 0; }
      .section-contact .container-section .left-container .bg-image {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        background: white url(src/assets/image/contato/bg-contato-90fe09a8.jpg) no-repeat;
        background-size: cover; }
    .section-contact .container-section h2 {
      font-size: 36px;
      padding: 0;
      margin: 0;
      line-height: 50px; }
      .section-contact .container-section h2 span {
        font-size: 24px;
        display: block; }
  .section-contact ul {
    margin: 40pxs 0 0 0;
    padding: 0;
    list-style: none; }
    .section-contact ul li {
      width: 100%;
      display: block;
      padding: 10px 0; }
      .section-contact ul li a {
        text-decoration: none;
        color: #fff;
        font-weight: bold;
        font-size: 18px; }
      .section-contact ul li .icon {
        font-size: 18px;
        line-height: 30px; }
      .section-contact ul li span {
        display: block;
        font-size: 12px; }

.section-contact.blackColor .container-section,
.section-contact.blackColor ul li a {
  color: #000; }

@media (max-width: 801px) {
  .section-contact {
    display: block; }
    .section-contact .layer-transparent {
      width: 100%; }
    .section-contact .container-section {
      height: auto; }
      .section-contact .container-section .right-container {
        width: auto;
        display: block;
        position: relative; }
        .section-contact .container-section .right-container .contact-container {
          width: auto;
          display: block;
          padding: 30px 15px;
          right: auto;
          height: auto;
          position: relative;
          top: 0;
          left: 0; }
    .section-contact .left-container {
      display: none; }
    .section-contact ul li {
      padding: 10px 0; } }
